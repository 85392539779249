body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.center-component {
  position: relative;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.center-component > form, .bordered {
  padding: 15px;
  border: 1px solid rgb(193, 193, 193);
  border-radius: 5px;
}

.react-calendar {
  width: 100% !important;
}

.calendarEntryDeleteButton {
  float: right;
}

.jobs {
  display: inline;
}

.jobsDeleteButton {
  float: right;
  line-height: 1 !important;
}

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.jobEntryForm {
  padding: 15px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 5px;
}

.selectedDay {
  color: white;
  background-color: var(--success) !important;
}